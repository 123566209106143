<template>
    <div class="photo text-center">
        <div id="foto-nd" class="carousel slide">
            <div class="carousel-inner">
                <template v-for="(foto_cesta, key) in fotoArray">
                    <div class="carousel-item" :id="'carousel-item-' + key" v-bind:class="{'active': key == 0}">
                        <img class="d-block img-responsive" :src="adminDir  + 'img.php?img=' + foto_cesta + '&width=200&height=200'" :alt="nazev + '_' + key" v-touch:swipe.left="swipeLeftHandler" v-touch:swipe.right="swipeRightHandler" @click="zobrazitDetailFoto(foto_cesta)">
                        <div class="pending-block"></div>
                    </div>
                </template>
            </div>

            <template v-if="showArrows == true">
                <a class="carousel-control-prev" href="#foto-nd" role="button" @click="carouselActivatePosun(-1)" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true">
                        <fa-icon icon="chevron-left"></fa-icon>
                    </span>
                </a>
                <a class="carousel-control-next" href="#foto-nd" role="button" @click="carouselActivatePosun(1)" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true">
                        <fa-icon icon="chevron-right"></fa-icon>
                    </span>
                </a>
            </template>
        </div>

        <ol class="carousel-indicators">
            <template v-for="(foto_cesta, key) in fotoArray">
                <li v-if="key <= 17" data-target="#foto-nd" @click="carouselActivate(key)" :data-slide-to="key" :id="'carousel_activate_'+ key" v-bind:class="{'active': key == 0}"></li>
            </template>
        </ol>
    </div>
</template>

<script>
import router from "../router";
export default {
    name: "TheCarousel",
    props: {
        fotoArray: {},
        nazev: ''
    },
    data() {
        return {
            pocetFoto: 0,
            showArrows: false,
            carouselActiv: 0,
            start: 1,
            adminDir: window.adminDir
        }
    },
    created() {
        //this.pocetFoto = this.fotoArray.length;
    },
    methods: {
        carouselActivatePosun(posun) {
            let cislo = this.carouselActiv;

            cislo = cislo + (posun);
            if (cislo < 0) {
                cislo = this.pocetFoto - 1;
            }
            if (cislo >= this.pocetFoto) {
                cislo = 0;
            }
            this.carouselActivate(cislo);
        },
        carouselActivate(cislo) {
            this.pocetFoto = this.fotoArray.length;

            let className = 'animation-left';
            let pocetFotoCaoursel = this.pocetFoto - 1;
            if (cislo > this.carouselActiv || (cislo == 0 && this.carouselActiv == pocetFotoCaoursel))
            {
                className = 'animation-right';
            }
            if (cislo == pocetFotoCaoursel && this.carouselActiv == 0)
            {
                className = 'animation-left';
            }

            var element = document.getElementById("carousel_activate_" + this.carouselActiv);
            element.classList.remove("active");
            var element = document.getElementById("carousel_activate_" + cislo);
            element.classList.add("active");
            this.carouselActiv = cislo;


            $('.carousel-item').removeClass('active');
            $('#carousel-item-' + cislo).addClass('active').addClass(className);
            setTimeout(function(){
                $('#carousel-item-' + cislo).removeClass(className);
            }, 20);

        },
        swipeLeftHandler()
        {
            this.pocetFoto = this.fotoArray.length;
            this.carouselActivatePosun(1);
        },
        swipeRightHandler()
        {
            this.pocetFoto = this.fotoArray.length;
            this.carouselActivatePosun(-1);
        },
        zobrazitDetailFoto(fotoCesta)
        {
            fotoCesta = btoa(unescape(encodeURIComponent(fotoCesta)));
            router.push('/detail/foto/' + fotoCesta);
        }

    }
}



</script>

<style lang="scss" scoped>
@import "../assets/scss/colors.scss";

.photo img
{
    margin: 0 auto;
}

.photo, #foto-nd {
    min-height: 220px;
}

img {
    max-height: 200px;
}

.carousel-item {

    border-radius: 4%;
    padding: 8px;
    height: 220px;
}

.carousel-indicators {

    position: relative;
    li {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-top: 10px;
        opacity: 1;
        background-color: $color-grey;
    }
    li.active {
        background-color: $color-black;
    }
}

ul, ol {
    margin-bottom: 0em;
}

.carousel .carousel-control-next {
    .carousel-control-next-icon {
        position: absolute;
        padding: 10px;
        width: 20px;
        height: 80px;
        right: 70%;
        background-image: none;
        color: $color-dark;
        font-size: 3em;
        border-right: none;
    }
}

.carousel .carousel-control-prev {
    .carousel-control-prev-icon {
        position: absolute;
        padding: 10px;
        width: 20px;
        height: 80px;
        left: 1%;
        background-image: none;
        color: $color-dark;
        font-size: 3em;
        border-right: none;
    }

}
.carousel-item-left, .carousel-item-prev,
 .carousel-item-right, .carousel-item-next {
    display: none!important;
}


.animation-right {
    transform: translateX(80%);
}


.animation-left {
    transform: translateX(-80%);
}

</style>