<template>
    <div class="mb-5" :key="'detail-polozky-' + keyDetailPolozky">
        <the-carousel :nazev="detailPolozky.nazev_polozky" :fotoArray="detailPolozky.cesty_fotek"></the-carousel>
        <div class="row detail-polozky" v-if="detailPolozky.skladove_cislo > 0">
            <template v-if="cisloPolozky > 0">
                <template v-if="detailPolozky.existuje_skladem == 1">
                    <div class="col-12 text-bold text-danger mt-2 mb-2 text-center" v-if="detailPolozky.pocet_rezervaci_pol_nd_polozka > 0">
                        Tato položka je rezervovaná pro prodej
                    </div>
                </template>
                <template v-if="detailPolozky.existuje_skladem == 0">
                    <div class="col-12 text-bold text-danger mt-2 mb-2 text-center" v-if="detailPolozky.existuje_vydej == 1">
                        Tato položka již byla vyskladněna
                    </div>
                    <div class="col-12 text-bold text-danger mt-2 mb-2 text-center" v-if="detailPolozky.jiny_sklad == 1">
                        Tato položka se nachází v jiném skladě
                    </div>
                </template>

            </template>

            <div class="col-4 text-right">Skladové číslo</div>
            <div class="col-8 text-left text-bold">{{ detailPolozky.skladove_cislo }}</div>
            <div :class="'col-4 text-right'" v-if="detailPolozky.typ_polozkovani == 'pol' && cisloPolozky > 0">Číslo položky</div>
            <div :class="'col-8 text-left text-bold'" v-if="detailPolozky.typ_polozkovani == 'pol' && cisloPolozky > 0">
                <span class="text-success" v-if="detailPolozky.pouzite == 1"> {{ cisloPolozky }}</span>
                <span v-else>{{ cisloPolozky }}</span>
            </div>
            <div class="col-4 text-right">Název</div>
            <div class="col-8 text-left text-bold">{{ detailPolozky.nazev_polozky }}</div>

            <div class="col-4 text-right">Položkování</div>
            <div class="col-8 text-left text-bold">
                <span class="badge bg-secondary text-white p-1" v-if="detailPolozky.typ_polozkovani == 'pol'">Položkovatelná</span>
                <span class="badge bg-secondary text-white p-1" v-if="detailPolozky.typ_polozkovani == 'nepol'">Nepoložkovatelná</span>
            </div>

            <div :class="'col-4 text-right'" v-if="detailPolozky.typ_polozkovani == 'pol' && cisloPolozky > 0 && detailPolozky.existuje_skladem == 1">Pozice</div>
            <div class="col-8 text-left text-bold" v-if="detailPolozky.typ_polozkovani == 'pol' && cisloPolozky > 0 && detailPolozky.existuje_skladem == 1">
                <router-link
                    :to="'/detail/parkovaci-pozice/' + detailPolozky.id_pozice_regalu"
                    v-if="detailPolozky.id_pozice_regalu > 0"
                >
                    {{ detailPolozky.kod_regalu }} - {{ detailPolozky.kod_pozice }}
                </router-link>
                <span class="text-danger" v-if="(detailPolozky.id_pozice_regalu == 0 || detailPolozky.id_pozice_regalu == null) && detailPolozky.existuje_skladem == 1">
                    Nezaparkováno
                </span>
            </div>
            <div :class="'col-4 text-right'" v-if="detailPolozky.typ_polozkovani != 'pol'">Skladem</div>
            <div class="col-8 text-left text-bold" v-if="detailPolozky.typ_polozkovani != 'pol'">
                {{ detailPolozky.celkem_skladem_nove }}
                <template v-if="detailPolozky.celkem_skladem_pouzite > 0">
                    /
                    <span class="text-success">{{ detailPolozky.celkem_skladem_pouzite }}</span>
                </template>
                {{ detailPolozky.merna_jednotka }}
            </div>
        </div>

        <!-- Zaparkované položky -->
        <the-card class="mt-2 mb-2" :default-open="false">
            <template v-slot:header>
                Zaparkované položky
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>Parkovací pozice</th>
                                <th>Množství</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(regal, id) in zaparkovanePolozky">
                                <td class="text-center">{{ regal.nazev_regalu }} - {{ regal.kod_pozice }}</td>
                                <td class="text-center">
                                    {{ regal.mnozstvi_nove | formatNumber }}
                                    <template v-if="regal.mnozstvi_pouzite > 0">
                                        /
                                        <span class="text-success">{{ regal.mnozstvi_pouzite | formatNumber }} {{ detailPolozky.merna_jednotka }}</span>
                                    </template>

                                    {{ detailPolozky.merna_jednotka }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <!-- Historie parkování -->
        <the-card class="mt-2 mb-2" :default-open="false">
            <template v-slot:header>
                Historie parkování
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th><i class="fas fa-calendar-alt"></i> </th>
                                <th><i class="fas fa-plus-circle"></i> </th>
                                <th><i class="fas fa-minus-circle"></i></th>
                                <th v-if="detailPolozky.typ_polozkovani == 'nepol'"><i class="fas fa-calculator"></i></th>
                                <th><i class="fas fa-user-alt"></i> </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(historie, id) in historieParkovani">
                                <td class="text-center"> {{ historie.datum_format }} </td>
                                <td class="text-center"> {{ historie.nazev_regalu_nova }} - {{ historie.kod_pozice_nova }} </td>
                                <td class="text-center"> {{ historie.nazev_regalu_puvodni }} - {{ historie.kod_pozice_puvodni }} </td>
                                <td class="text-center" v-if="detailPolozky.typ_polozkovani == 'nepol'">
                                    {{ historie.pocet_mj_nove }}
                                    <span class="text-success" v-if="historie.pocet_mj_pouzite > 0"> / {{ historie.pocet_mj_pouzite }}</span>
                                    {{ detailPolozky.merna_jednotka }}
                                </td>
                                <td class="text-center"> {{ historie.zkratka_uzivatele }} </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <!-- Dodavatelé -->
        <the-card class="mt-2 mb-2" :default-open="false">
            <template v-slot:header>
                Dodavatelé <small>({{ Object.keys(modelovaSkladovaCislaSpolecnosti).length }})</small>
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>Společnost</th>
                                <th>Skladové č.</th>
                                <th>Modelové č.</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(spolecnost, id) in modelovaSkladovaCislaSpolecnosti">
                                <td><span :title="spolecnost.nazev_spolecnosti">{{ spolecnost.nazev_spolecnosti | maxLength(20) }}</span></td>
                                <td class="">{{ spolecnost.skladove_cislo }}</td>
                                <td class="">{{ spolecnost.modelove_cislo }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <!-- Parametry -->
        <the-card class="mt-2 mb-2" :default-open="false">
            <template v-slot:header>
                Parametry
            </template>
            <template v-slot:body>
                <div class="table-content">
                    <div class="table-wrapper">
                        <table class="table table-bordered table-bordered-dark table-striped table-hover">
                            <thead>
                            <tr class="text-center">
                                <th>Parametr</th>
                                <th>Hodnota</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(parametr, id) in parametry">
                                <td>{{ parametr.nazev_parametru }}</td>
                                <td>{{ parametr.hodnota | formatNumber}} <span v-if="parametr.merna_jednotka != 'Žádná měrná jednotka'">{{ parametr.merna_jednotka }}</span></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </template>
        </the-card>

        <!-- Skladové zásoby -->
        <the-card class="mt-2 mb-5" :default-open="true">
            <template v-slot:header>
                Skladové zásoby
            </template>
            <template v-slot:body>
                <h5 class="">Celkem skladem:
                    <b>
                        {{ skladoveZasobyCelkem['mnozstvi_zustatek_nove'] }}
                        <template v-if="skladoveZasobyCelkem['mnozstvi_zustatek_pouzite'] > 0">/</template>
                        <span v-if="skladoveZasobyCelkem['mnozstvi_zustatek_pouzite'] > 0" class="text-success">
                            {{ skladoveZasobyCelkem['mnozstvi_zustatek_pouzite'] }}
                        </span>
                        {{ detailPolozky.merna_jednotka }}
                    </b>
                </h5>

                <template v-for="(skladArray, idSkladu) in skladoveZasoby.sklady">
                    <div class="top-20">
                        <div class="table-content">
                            <div class="table-wrapper">
                                <table class="table table-bordered table-striped table-hover">
                                    <thead>
                                    <tr class="text-center text-small">
                                        <th>Spol.</th>
                                        <th>Sklad</th>
                                        <th>Nové</th>
                                        <th>Použité</th>
                                    </tr>
                                    </thead>
                                    <tbody class="text-small">
                                    <tr v-bind:class="{'background-warning': idAktualnihoSkladu == idSkladu}">
                                        <td>{{ skladArray.sklad.nase_spolecnost }}</td>
                                        <td>{{ skladArray.sklad.nazev_skladu }}</td>
                                        <td class="text-center">{{ skladArray.stav.pocet_mj_nove }} {{ detailPolozky.merna_jednotka }}</td>
                                        <td class="text-center text-success">{{ skladArray.stav.pocet_mj_pouzite }} {{ detailPolozky.merna_jednotka }}</td>

                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>


                </template>

            </template>
        </the-card>

    </div>
</template>

<script>
import axiosApiPostV2 from "@/router/axiosApiMethod";
import store from "../../../store";
import TheCarousel from "../../../components/TheCarousel";
import TheCard from "../../../components/TheCard";
import {busBarcodeZmenaParkovaciPoziceDetailPolozky, busAlert, busReload} from "../../../main";
import barcode from "../../../store/barcode/barcode";
import router from "../../../router";
export default {
    components: {
        TheCarousel, TheCard
    },
    name: "DetailPolozky",
    data() {
        return {
            idPolNdPolozky: 0,
            skladoveCislo: 0,
            cisloPolozky: 0,
            detailPolozky: {},
            idAktualnihoSkladu: 0,
            historieParkovani: {},
            zaparkovanePolozky: {},
            modelovaSkladovaCislaSpolecnosti: {},
            parametry: {},
            povoleneParkovani: true,
            keyDetailPolozky: 0,
            skladoveZasoby: {},
            skladoveZasobyCelkem: {}
        }
    },
    created() {
        this.$root.$emit('showLoader', {show: true});
        this.idAktualnihoSkladu = store.state.sklad.id_skladu;
        this.idPolNdPolozky = this.$route.params.id_pol_nd_polozky;
        this.skladoveCislo = this.$route.params.skladove_cislo;
        this.cisloPolozky = this.$route.params.cislo_polozky;

        this.detailPolozkyMethod();

        busBarcodeZmenaParkovaciPoziceDetailPolozky.$on('loadBarcode', args => {
            let akcniCislo = args.barcode.akcni_cislo;
            if (akcniCislo == 1)
            {
                let idParkovaciPozice = args.barcode.id_parkovaci_pozice;
                if (idParkovaciPozice > 0)
                {
                    if (this.cisloPolozky > 0)
                    {
                        let regalyObj = {
                            id_parkovaci_pozice: idParkovaciPozice,
                            skladove_cislo: this.skladoveCislo,
                            pocet_mj: 1
                        };
                        axiosApiPostV2('regaly-mozno-zaparkovat', regalyObj, 'data').then(result => {
                            if (result.status == true || result.status == 'ok')
                            {
                                let sendObj = {id_pozice_regalu: idParkovaciPozice, skladove_cislo: this.skladoveCislo, cislo_polozky: this.cisloPolozky, id_pol_nd_polozky: this.idPolNdPolozky};
                                axiosApiPostV2('preparkovani-polozky', sendObj, 'result').then(result => {
                                    busAlert.$emit('alertMessage', {text: 'Náhradní díl byl přeparkován!', color: 'success', autoHide: 3, strong: ''});
                                    this.detailPolozkyMethod();
                                });
                            }
                            else
                            {
                                $.playSound(require('@/assets/sounds/cancel.mp3'));
                                busAlert.$emit('alertMessage', {text: result.text, color: 'danger', autoHide: 0, strong: 'Nelze zaparkovat!'});
                            }
                        });
                    }
                    else
                    {
                        $.playSound(require('@/assets/sounds/cancel.mp3'));
                        this.$root.$emit('alertMessage', {text: 'Tento náhradní díl nelze přeparkovat tímto způsobem! Využijte prosím modul pro parkování!.', color: 'danger', autoHide: 0, strong: 'Chyba při parkování!'})
                        return;
                    }
                }
                else
                {
                    $.playSound(require('@/assets/sounds/cancel.mp3'));
                    this.$root.$emit('alertMessage', {text: 'Nepovedlo se správně načíst parkovací pozici!.', color: 'danger', autoHide: 0, strong: 'Chyba při parkování!'})
                }

            }

            if (akcniCislo == 2)
            {
                this.skladoveCislo = args.barcode.skladove_cislo;
                this.cisloPolozky = args.barcode.cislo_polozky;
                this.idPolNdPolozky = args.barcode.id_pol_nd_polozky;
                this.detailPolozkyMethod();
            }
        });
    },
    filters: {
        maxLength(value, max = 35, addPoints)
        {
            return Helper.maxLength(value, max, addPoints);
        },
        formatNumber(value) {
            return Helper.formatNumber(value);
        },
        decimalNumber(value) {
            return Helper.decimalNumber(value);
        },
    },
    methods: {
        detailPolozkyMethod()
        {
            if (this.cisloPolozky == 0)
            {
                this.idPolNdPolozky = 0;
            }

            let rozsireni = {
                foto_array: true
            };

            let params = {
                id_pol_nd_polozky: this.idPolNdPolozky,
                skladove_cislo: this.skladoveCislo,
                cislo_polozky: this.cisloPolozky,
                rozsireni: JSON.stringify(rozsireni),
                return_detail: true
            };
            axiosApiPostV2('detail-polozky-v2', params, 'detail').then( result => {
                this.detailPolozky = result;

                let paramsOstatniDetaily = {
                    id_pol_nd_polozky: this.idPolNdPolozky,
                    skladove_cislo: this.skladoveCislo,
                    cislo_polozky: this.cisloPolozky,

                };
                axiosApiPostV2('detail-pol-nd-data', paramsOstatniDetaily, 'data').then( ostatniDetail => {
                    this.historieParkovani = ostatniDetail.historie_parkovani;
                    this.zaparkovanePolozky = ostatniDetail.zaparkovane_polozky;
                    this.modelovaSkladovaCislaSpolecnosti = ostatniDetail.modelova_skladova_cisla;
                    this.parametry = ostatniDetail.parametry;
                    this.skladoveZasoby = ostatniDetail.skladove_zasoby;
                    this.skladoveZasobyCelkem = this.skladoveZasoby['celkem'];



                    this.$root.$emit('showLoader', {show: false});
                    this.keyDetailPolozky++;
                });
            });
        },
    }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/colors";
.detail-polozky {
    border-bottom: 1px solid $color-grey;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    line-height: 16px;
}
.col-4, .col-8 {
    margin-top: 0.5em;
    font-size: 0.9em;
}
a {
    color: #4e4f4d;
    text-decoration: underline;
}
</style>