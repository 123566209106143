<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-11 text-bold" @click="setOpenCardHeader()">
                        <slot name="header"></slot>
                    </div>
                    <div class="col-1">
                        <i class="fas fa-chevron-down" v-if="openCard == false" @click="setOpenCard(true)"></i>
                        <i class="fas fa-chevron-up" v-if="openCard == true" @click="setOpenCard(false)"></i>
                    </div>
                </div>
            </div>
            <div class="card-body" v-if="openCard == true">
                <slot name="body"></slot>
            </div>
            <div class="card-footer text-right" v-if="(openCard == true && footer == true) || (footer == true && defaultFooterOpen == true)">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TheCard",
    props: {
        footer: false,
        defaultOpen: true,
        defaultFooterOpen: false
    },
    data() {
        return {
            openCard: true
        }
    },
    created() {
        if (this.defaultOpen != undefined)
        {
            this.openCard = this.defaultOpen;
        }

        //console.log(this.openCard);
    },
    methods: {
        setOpenCard(value)
        {
            this.openCard = value;
        },
        setOpenCardHeader()
        {
            let newValue = true;
            if (this.openCard == true)
            {
                newValue = false;
            }
            this.setOpenCard(newValue);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>